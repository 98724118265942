<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-row class="main-title-img-2" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            {{this.roomInfo.roomNm}}
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="pa-3">
            <v-col>
                <v-container class="">

                    <v-row>
                        <v-col cols="12">
                            <ul style="padding-inline-start: 0" class="roundBtn-4-o">
                                <li v-for="(item, i) in roomList" :key="i" class="roomSubMenu_li">
                                    <a :id="item.roomId" @click="menuClick(item)"> {{item.roomNm}}</a>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-carousel
                                    :continuous="true"
                                    cycle
                                    hide-delimiter-background
                                    :height="$vuetify.breakpoint.xs ? 300 : 600"
                            >
                                <v-carousel-item
                                        v-for="(roomImg, i) in roomInfo.roomImg"
                                        :key="i"
                                        :src="roomImg.roomImgRoute"
                                        inset
                                >
                                </v-carousel-item>
                            </v-carousel>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">객실요금</h1>
                        </v-col>
                        <v-col cols="12" md="12" class="hidden-sm-and-down">
                            <table class="table-room" border="1">
                                <tr>
                                    <th>객실명</th>
                                    <th>인원</th>
                                    <th>형태</th>
                                    <th>구분</th>
                                    <th>비수기</th>
                                    <th>준성수기</th>
                                    <th>성수기</th>
                                </tr>
                                <tr>
                                    <td rowspan="2">{{this.roomInfo.roomNm}}</td>
                                    <td rowspan="2">기준{{this.roomInfo.stdNop}}/최대{{this.roomInfo.maxNop}}</td>
                                    <td rowspan="2">{{this.roomInfo.roomForm}}</td>
                                    <td>주중</td>
                                    <td>{{this.roomInfo.osrWe}}</td>
                                    <td>{{this.roomInfo.spsrWe}}</td>
                                    <td>{{this.roomInfo.psrWe}}</td>
                                </tr>
                                <tr>
                                    <td>주말</td>
                                    <td>{{this.roomInfo.osrWd}}</td>
                                    <td>{{this.roomInfo.spsrWd}}</td>
                                    <td>{{this.roomInfo.psrWd}}</td>
                                </tr>
                            </table>
                        </v-col>

                        <v-col cols="12" md="12" class="hidden-md-and-up">
                            <table class="table-room" border="1">
                                <tr>
                                    <th>객실명</th>
                                    <th>인원</th>
                                    <th>형태</th>
                                </tr>
                                <tr>
                                    <td rowspan="2">{{this.roomInfo.roomNm}}</td>
                                    <td rowspan="2">기준{{this.roomInfo.stdNop}}/최대{{this.roomInfo.maxNop}}</td>
                                    <td rowspan="2">{{this.roomInfo.roomForm}}</td>
                                </tr>
                            </table>
                            <table class="table-room mt-8" border="1">
                                <tr>
                                    <th>구분</th>
                                    <th>비수기</th>
                                    <th>준성수기</th>
                                    <th>성수기</th>
                                </tr>
                                <tr>
                                    <td>주중</td>
                                    <td>{{this.roomInfo.osrWe}}</td>
                                    <td>{{this.roomInfo.spsrWe}}</td>
                                    <td>{{this.roomInfo.psrWe}}</td>
                                </tr>
                                <tr>
                                    <td>주말</td>
                                    <td>{{this.roomInfo.osrWd}}</td>
                                    <td>{{this.roomInfo.spsrWd}}</td>
                                    <td>{{this.roomInfo.psrWd}}</td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="roomInfoDesc">
                                <ul>
                                    <li>
                                        성수기: 07-01 ~ 08-31
                                    </li>
                                    <li>
                                        준성수기: 06-01 ~ 06-30, 09-01 ~ 09-30
                                    </li>
                                    <li>
                                        체크인 : 14:00 이후 / 체크아웃 : 정오 12:00 이전
                                    </li>
                                    <li>
                                        기준인원 외 1인 초과당 추가요금 5000원이 적용됩니다.
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="my-5">
                        <v-col cols="12" md="12" class="mb-3">
                            <h1 class="about-sub-title">환불규정</h1>
                        </v-col>
                        <v-col cols="12" md="12">

                            <div class="roomInfoDesc">
                                <ul>
                                    <li>
                                        환불시에는 총금액에서 환불규정에 따라 환불해 드립니다.
                                    </li>
                                    <li>이용일 10일전 100%환불</li>
                                    <li>이용일 9일전 90%환불</li>
                                    <li>이용일 7일전 70%환불</li>
                                    <li>이용일 5일전 50%환불</li>
                                    <li>이용일 4일전 40%환불</li>
                                    <li>이용일 3일전 30%환불</li>
                                    <li>이용일 2일전 환불불가 입니다.</li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="my-8" v-if="isCampA">
                        <v-col cols="12" md="12">
                            <h1 class="about-sub-title">캠핑장-A의 차별화</h1>
                        </v-col>
                        <v-col cols="12" md="12" class="font-size-1rem">
                            <p class="sub-title-2">1. 그늘막이 있어 타프가 필요없습니다.</p>
                            <p><span class="sub-text-em1">따가운 햇빛과 자외선 때문에 캠핑이 두려워 그늘막으로 타프를 이용하지만,</span>
                                타프를 쳤다 접었다 해야만 하는 번거로움과 타프로 인한 짐이 부담스러울대
                                가벼운 마음으로 떠나고 싶을때 <span class="sub-text-em2">별도의 그늘막이 있는 캠핑장-A를 이용하시면</span> <span
                                        class="sub-text-em1">최고의 캠핑을 즐길 수 있습니다.</span></p>
                            <p class="sub-title-2">2. 비가와도 즐겁습니다.</p>
                            <p>캠핑을 즐기러와서 비가오면 낭패를 보는 경우가 종종 발생합니다.<br>
                                이때 비를 피할 수 없으면 텐트안에서 하루종일 지내야 하는데, 저희 캠핑장은 그런 걱정은 하지 않으셔도 됩니다.<br>
                                비가와도 걱정없이 텐트옆에서 비가 내리는 모습과 빗소리를 들으며 계곡의 힘찬 물줄기가 흐르는 것을 보면,
                                태풍에 바다의 파도를 보는 것처럼 색다른 즐거움을 느끼실 수 있습니다.</p>
                            <p class="sub-title-2">3. 바람이 불어도 괜찮습니다.</p>
                            <p>바람이 거세게 불면 텐트가 흔들리고 음식등을 할때 많은 불편을 느껴본적이 많습니다.<br>
                                하지만, <span class="sub-text-em1">저희 캠핑장은 바람막이를 내리면 바람이 차단되어,</span> 텐트가 바람에 흔들리거나 흠식을 함에
                                있어 불판함이 없으니 많은 이용 바랍니다.</p>
                            <p class="sub-title-2">4. 평상 1개가 기본 제공됩니다.</p>

                        </v-col>
                    </v-row>

                    <v-row class="my-8">
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">캠핑장의 특장점</h1>
                        </v-col>
                        <v-col cols="12" md="12" class="font-size-1rem">
                            <p class="sub-title-2">1. 캠핑장 바로 앞에 계곡이 있습니다.</p>
                            <p>아이들이 안전하게 노는 모습과 힘찬 계곡물이 흐르는 것을 바로 앞에서 보고 느끼실 수 있습니다.</p>
                            <p class="sub-title-2">2. 운주계곡 최상류에 위치하고 있습니다.</p>
                            <p>하류의 펜션들은 이웃하는 펜션들 때문에 계곡트래킹이 어려우나 저희 캠핑장은 <span class="sub-text-em1">최상류 집으로 계곡트래킹과 등산 또는 올레길 체험을 동시에 하실 수 있는
                                캠핑 + 트래킹 + 등상(올레길 산책)</span> 등을 한번에 즐길 수 있는 <span class="sub-text-em2">삼위일체의 캠핑장</span>입니다.
                            </p>
                            <p class="sub-title-2">3. 펜션과 함께하고 있습니다.</p>
                            <p>때로는 어르신들과 아이들이 같이 오면 <span class="sub-text-em1">어르신들은 펜션에서, 아이들은 캠핑장에서</span> 즐거움을 동시에
                                느낄수 있습니다.</p>
                            <p class="sub-title-2">4. 수영장을 함께하고 있습니다.</p>
                            <p>아이들이 마음껏 물놀이 할수 있으며, 가뭄이 들어 물이 없어도 물놀이 걱정하지 않으셔도 됩니다.</p>

                        </v-col>
                    </v-row>
                    <v-row class="my-8">
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">공통사항</h1>
                        </v-col>
                        <v-col cols="12" md="12" class="font-size-1rem">
                            <p class="sub-title-2">1. 상품의 종류</p>
                            <p>
                                가. "A"상품: 비와 바람을 막을 수 있으며, <span class="sub-text-em1">그늘막과 평상이 있는</span> 특화된 상품<br>
                                나. "B"상품: 계곡과 더불어 <span class="sub-text-em1">자연의 맛을 그대로 즐길수 있는</span> 고품격 상품
                            </p>
                            <p class="sub-title-2">2. 이용시간</p>
                            <p>
                                비성수기: 익일 12시까지 입니다.(조금 일찍 오셨다가 늦게 나가셔도 됩니다.<br>
                                성 수 기: 익일 12시까지 입니다. <span class="sub-text-em2">성수기에는 이용시간을 꼭 준수하여 주시면 감사합니다.</span>
                            </p>
                            <p class="sub-title-2">3. 텐트대여</p>
                            <p>
                                캠핑장 예약시 <span class="sub-text-em1">부대설비에서 대여텐트를 클릭하시면</span> 텐트를 대여해 드립니다.<br>
                                이때 텐트만 대여하므로 <span class="sub-text-em1">이불과 조리기구 등은 준비하셔야 합니다.</span><br>
                                <span class="sub-text-em2">단 A형 캠핑장 예약 고객분만 적용 됩니다.</span>
                            </p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <ul style="width:100%; margin:0 auto !important;" class="roundBtn-4-o">
                                <li></li>
                                <li class="roomSubMenu_li">
                                    <a @click="btnClick(0)">목록</a>
                                </li>
                                <li class="roomSubMenu_li">
                                    <a @click="btnClick(1)">예약</a>
                                </li>
                                <li></li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'CampInfo',
        components: {},
        data: () => ({

            roomInfo: {},
            roomList: [],
            isCampA: false,

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);

            if (this.$store.state.room.roomInfo.roomId === "") {
                alert("객실 ID가 없습니다. 다시 시도해주세요.");
                window.history.back();
            }
            this.roomInfo = JSON.parse(JSON.stringify(this.$store.state.room.roomInfo));

            if (this.roomInfo.roomGubun != undefined && this.roomInfo.roomGubun === 'A') {

                this.isCampA = true;
            } else {
                this.isCampA = false;
            }

            this.getList();

        },
        methods: {
            getList(val) {
                return this.$store.dispatch("room/getCampList", val)
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;

                            for (let i = 0; i > resp.message.length; i++) {

                                if (resp.message[i].roomId === this.roomInfo.roomId) {
                                    this.roomInfo = resp.message[i];
                                }

                            }
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            menuClick(item) {

                this.$store.commit("room/SAVE_ROOM_INFO", item);
                this.$router.go();
            },
            btnClick(val) {

                switch (val) {
                    case 0 : {
                        this.$router.push({name: "CampList"});
                        break;
                    }
                    case 1: {
                        this.$router.push({name: "ReservationSearch", params: this.roomInfo});
                        break;
                    }
                }

            }
        },
        mounted() {
        },
        updated: function () {  // 페이지 전부 생성후 자동 실행
            this.$nextTick(function () {
                //$('.roomSubMenuTable > tr > th').removeClass("active");
                $('.roomSubMenu_li > a').removeClass("active");
                $("#" + this.roomInfo.roomId).addClass("active");

            })
        }

    }
</script>

